<template>
  <div>
    <b-modal
      size="xl"
      ref="modalLogin"
      id="modalLogin"
      modal-class="p-0"
      centered
      scrollable
      hide-header
      hide-footer
      @hide="$store.dispatch('setProductAddToCartAfterLogin', null)"
    >
      <button
        type="button"
        @click="$bvModal.hide('modalLogin')"
        class="close"
        data-dismiss="modal"
      >
        <img src="@/assets/img/ic-close.svg" alt="" />
      </button>
      <div class="row no-gutters">
        <div class="col d-none d-md-block">
          <div class="login-img">
            <b-img-lazy
              fluid-grow
              class="rounded-left"
              :src="require('@/assets/img/maskot-log-in.png')"
              alt=""
            />
          </div>
        </div>
        <div class="col p-3 d-flex flex-column">
          <form @submit.prevent="handleSubmit">
            <h2 class="mt-4 mt-lg-0 mb-5">Sign in</h2>
            <!-- Username (Email / Phone Number) -->
            <MyInputFormText
              id="userName"
              label="Email/Phone Number"
              v-model.trim="$v.userName.$model"
              placeholder="Username"
              :classComponent="{
                'is-invalid-custom': $v.userName.$error,
              }"
              :invalidMessage="[
                !$v.userName.required
                  ? 'Butuh email / nomor telepon kamu nih'
                  : null,
              ]"
              redAsterisk="true"
            />
            <!-- Password -->
            <MyInputFormText
              id="password"
              label="Password"
              type="password"
              v-model="$v.password.$model"
              placeholder="Password"
              :classComponent="{
                'is-invalid-custom': $v.password.$error,
              }"
              :invalidMessage="[
                !$v.password.required
                  ? 'Butuh kata sandi kamu nih'
                  : null,
              ]"
              redAsterisk="true"
            />
            <!-- Forgot Password -->
            <div
              class="d-flex align-items-center justify-content-between"
            >
              <p class="text-right mt-3 ml-auto">
                <a
                  v-b-modal="'modalForgotPassword'"
                  @click="$bvModal.hide('modalLogin')"
                  >Forgot Password?</a
                >
              </p>
            </div>
            <!-- Error Message -->
            <div class="text-center alert alert-danger" v-if="error">
              <p class="my-2">{{ error }}</p>
              <a
                v-if="
                  userType != 'member' &&
                  /payment/i.test(error) &&
                  getCookie('associatePaymentUrl')
                "
                :href="getCookie('associatePaymentUrl')"
                class="text-info"
              >
                Complete payment now
              </a>
              <a
                v-else-if="
                  userType != 'member' &&
                  /payment/i.test(error) &&
                  !getCookie('associatePaymentUrl')
                "
                href="#"
                class="text-info"
                v-b-modal.modal-register-reseller
                @click="$bvModal.hide('modalLogin')"
              >
                Refill registration form
              </a>
              <a
                v-else-if="
                  userType == 'member' &&
                  error.toLowerCase().includes('activ')
                "
                href="#"
                class="text-info"
                @click="resendActivation"
              >
                Resend activation message
              </a>
            </div>
            <!-- Login Button -->
            <div class="text-center">
              <button
                type="submit"
                class="btn btn-wz-proceed btn-block"
                :disabled="isLoading"
              >
                LOGIN
                <font-awesome v-if="isLoading" icon="spinner" spin />
              </button>
            </div>
          </form>
          <div class="login-socmed text-center">
            <div class="mb-2">or login with</div>
            <!-- <a href=""><img src="@/assets/img/ic-fb.svg" /></a> -->
            <!-- <button  @click="handleClickSignIn" v-if="!isSignIn" :disabled="!isInit" ><img src="@/assets/img/ic-google.svg" /></button > -->
            <button
              type="button"
              @click="handleClickSignIn()"
              class="btn btn-wz-link"
            >
              <img src="@/assets/img/ic-google.svg" alt="" />
            </button>

            <p class="text-register">
              Don’t have an account?<br />
              It takes only one minute to
              <a
                href="#"
                data-dismiss="modal"
                data-toggle="modal"
                @click="$bvModal.hide('modalLogin')"
                v-b-modal="'modalRegister'"
                >Sign Up</a
              >
            </p>
          </div>
          <!-- <div class="mt-auto d-flex">
            <button
              type="button"
              class="btn btn-buynow-gold-outline m-auto"
              @click="$bvModal.hide('modalLogin')"
              v-b-modal.modal-register-reseller
            >
              Join Whizliz Business Partner
            </button>
          </div> -->
          <a
            class="font-14px bold text-center mt-3 mb-2 text-wz-underline"
            @click="$bvModal.hide('modalLogin'); $store.dispatch('setIsJuene', true)"
            v-b-modal.modalForgotPassword
          >
            I already have Juene Jewelry Account
          </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MyInputFormText from "@/components/form/MyInputFormText";
import { toaster } from "@/_helpers";
import { userService } from "@/_services";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ModalLogin",
  components: {
    MyInputFormText,
  },
  data() {
    return {
      userName: "",
      password: "",
      userType: null,
      isLoading: false,
      error: "",
      isInit: false,
      isSignIn: false,
      loginApp: "",
      errorMessage: [],
    };
  },
  validations: {
    userName: {
      required,
    },
    password: {
      required,
    },
  },
  mounted() {
    let that = this;
    let checkGauthLoad = setInterval(function () {
      that.isInit = that.$gAuth.isInit;
      that.isSignIn = that.$gAuth.isAuthorized;
      if (that.isInit) clearInterval(checkGauthLoad);
    }, 1000);
  },
  methods: {
    getCookie(cname) {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return;
    },
    async handleSubmit() {
      try {
        this.isLoading = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          toaster.make("Please fill the form correctly", "danger");
          this.isLoading = false;
        } else {
          const res = await userService.login(this.userName, this.password);
          if (res.profile_data) {
            this.isLoading = false;
            this.$helpers.user.addProductToCartAfterLogin(); // * (add product to cart automatically after user login - when user try to add to bag from: product detail, product hover & modal add bundle)
            this.$bvModal.hide("modalLogin");
            toaster.make("Welcome, " + res.profile_data.full_name, "success");
            return this.$emit("ModalLoginToken", res.access_token);
          } else {
            this.error = res.data.message;
            this.userType = res.data.data;
            this.isLoading = false;
          }
        }
      } catch (e) {
        this.isLoading = false;
        console.error(e);
      }
    },
    async handleClickSignIn() {
      try {
        this.loginApp = "google";
        const user = await this.$gAuth.signIn();
        const data = await userService.socialLogin(
          this.loginApp,
          user,
          "login"
        );
        if (data.profile_data) {
          this.isSignIn = this.$gAuth.isAuthorized;
          toaster.make("Welcome, " + data.profile_data.full_name, "success");
          return this.$emit("ModalLoginToken", data.access_token);
        } else this.error = data.data.message;
      } catch (e) {
        console.error(e);
      }
    },
    resendActivation() {
      userService
        .resendActivation(this.userName, this.userType)
        .then((res) => toaster.make(res.data.message, "success"));
    },
  },
};
</script>

