var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modalLogin",attrs:{"size":"xl","id":"modalLogin","modal-class":"p-0","centered":"","scrollable":"","hide-header":"","hide-footer":""},on:{"hide":function($event){return _vm.$store.dispatch('setProductAddToCartAfterLogin', null)}}},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$bvModal.hide('modalLogin')}}},[_c('img',{attrs:{"src":require("@/assets/img/ic-close.svg"),"alt":""}})]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col d-none d-md-block"},[_c('div',{staticClass:"login-img"},[_c('b-img-lazy',{staticClass:"rounded-left",attrs:{"fluid-grow":"","src":require('@/assets/img/maskot-log-in.png'),"alt":""}})],1)]),_c('div',{staticClass:"col p-3 d-flex flex-column"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('h2',{staticClass:"mt-4 mt-lg-0 mb-5"},[_vm._v("Sign in")]),_c('MyInputFormText',{attrs:{"id":"userName","label":"Email/Phone Number","placeholder":"Username","classComponent":{
              'is-invalid-custom': _vm.$v.userName.$error,
            },"invalidMessage":[
              !_vm.$v.userName.required
                ? 'Butuh email / nomor telepon kamu nih'
                : null,
            ],"redAsterisk":"true"},model:{value:(_vm.$v.userName.$model),callback:function ($$v) {_vm.$set(_vm.$v.userName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.userName.$model"}}),_c('MyInputFormText',{attrs:{"id":"password","label":"Password","type":"password","placeholder":"Password","classComponent":{
              'is-invalid-custom': _vm.$v.password.$error,
            },"invalidMessage":[
              !_vm.$v.password.required
                ? 'Butuh kata sandi kamu nih'
                : null,
            ],"redAsterisk":"true"},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}}),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('p',{staticClass:"text-right mt-3 ml-auto"},[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modalForgotPassword'),expression:"'modalForgotPassword'"}],on:{"click":function($event){return _vm.$bvModal.hide('modalLogin')}}},[_vm._v("Forgot Password?")])])]),(_vm.error)?_c('div',{staticClass:"text-center alert alert-danger"},[_c('p',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.error))]),(
                _vm.userType != 'member' &&
                /payment/i.test(_vm.error) &&
                _vm.getCookie('associatePaymentUrl')
              )?_c('a',{staticClass:"text-info",attrs:{"href":_vm.getCookie('associatePaymentUrl')}},[_vm._v(" Complete payment now ")]):(
                _vm.userType != 'member' &&
                /payment/i.test(_vm.error) &&
                !_vm.getCookie('associatePaymentUrl')
              )?_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-register-reseller",modifiers:{"modal-register-reseller":true}}],staticClass:"text-info",attrs:{"href":"#"},on:{"click":function($event){return _vm.$bvModal.hide('modalLogin')}}},[_vm._v(" Refill registration form ")]):(
                _vm.userType == 'member' &&
                _vm.error.toLowerCase().includes('activ')
              )?_c('a',{staticClass:"text-info",attrs:{"href":"#"},on:{"click":_vm.resendActivation}},[_vm._v(" Resend activation message ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-wz-proceed btn-block",attrs:{"type":"submit","disabled":_vm.isLoading}},[_vm._v(" LOGIN "),(_vm.isLoading)?_c('font-awesome',{attrs:{"icon":"spinner","spin":""}}):_vm._e()],1)])],1),_c('div',{staticClass:"login-socmed text-center"},[_c('div',{staticClass:"mb-2"},[_vm._v("or login with")]),_c('button',{staticClass:"btn btn-wz-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleClickSignIn()}}},[_c('img',{attrs:{"src":require("@/assets/img/ic-google.svg"),"alt":""}})]),_c('p',{staticClass:"text-register"},[_vm._v(" Don’t have an account?"),_c('br'),_vm._v(" It takes only one minute to "),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modalRegister'),expression:"'modalRegister'"}],attrs:{"href":"#","data-dismiss":"modal","data-toggle":"modal"},on:{"click":function($event){return _vm.$bvModal.hide('modalLogin')}}},[_vm._v("Sign Up")])])]),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modalForgotPassword",modifiers:{"modalForgotPassword":true}}],staticClass:"font-14px bold text-center mt-3 mb-2 text-wz-underline",on:{"click":function($event){_vm.$bvModal.hide('modalLogin'); _vm.$store.dispatch('setIsJuene', true)}}},[_vm._v(" I already have Juene Jewelry Account ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }