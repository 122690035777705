<template>
  <div>
    <button type="submit" class="w-100 btn" :class="variant ? 'btn-' + variant : 'btn-wz-primary'" :disabled="disabledComponent"
      >{{
        disabledComponent
          ? !loadingText
            ? "Loading..."
            : loadingText
          : !submitText
          ? "Submit"
          : submitText
      }}
      <font-awesome
        :icon="!iconButton ? 'spinner' : iconButton"
        spin
        v-if="disabledComponent"
      ></font-awesome>
    </button>
  </div>
</template>
<script>
export default {
  name: "MyInputFormText",
  props: ["disabledComponent", "loadingText", "submitText", "iconButton", "variant"],
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    input(val) {
      this.model = val;
      this.$emit("input", val);
    },
  },
};
</script>
