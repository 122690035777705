<template>
  <div
    class="row justify-content-center no-gutters text-center"
    :class="[colNums]"
  >
    <p class="mt-1" v-if="upgrade">
      Upgrading your package will <b>NOT</b> reset your active membership period
    </p>
    <b-form
      v-for="(membership, i) in membershipList"
      :key="i"
      @submit.prevent="submitMembership(membership)"
      class="col p-1"
    >
      <div
        class="membership-card"
        :style="{
          '--left-col': membership.color_1,
          '--right-col': membership.color_2
        }"
      >
        <div class="d-flex flex-column h-100">
          <!-- <div class="row flex-grow-1 text-white">
            <div class="col text-left">
              <p class="bold m-0">{{ membership.name }} Membership</p>
              <small v-html="membership.description"></small>
            </div>
            <div class="col-auto">
              <b
                >Rp
                {{
                  $helpers.format.price(membership.price_upgrade || membership.price)
                }}</b
              >
            </div>
          </div> -->
          <div class="d-flex justify-content-between">
            <p class="bold text-left m-0">{{ membership.name }}</p>
            <div v-if="membership.discount > 0" class="text-right">
              <b>{{ $helpers.format.price((membership.price_upgrade || membership.price) - membership.discount) }}</b>
              <span class="old-price">{{ $helpers.format.price(membership.price_upgrade || membership.price) }}</span>
            </div>
            <b class="text-nowrap" v-else>
              {{ $helpers.format.price(membership.price_upgrade || membership.price) }}
            </b>
            <!-- <div class="d-block">
              <b>{{ $helpers.format.price(membership.price_upgrade || membership.price - membership.discount) }}</b>
              <br/>
              <small class="text-nowrap">
                <strike>
                  {{ $helpers.format.price(membership.price_upgrade || membership.price) }}
                </strike>
              </small>
            </div> -->
          </div>
          <small
            class="text-left flex-grow-1 mt-2"
            v-html="membership.description"
          ></small>
          <MyButtonSubmit
            class="mt-2"
            submitText="Buy Now"
            variant="buynow-beige"
            :disabledComponent="isLoading"
            v-b-modal.modal-membership-payment
          />
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
import MyButtonSubmit from "@/components/form/MyButtonSubmit";

export default {
  components: {
    MyButtonSubmit,
  },
  props: ["membershipList", "payment", "cols", "mobileCols", "upgrade", "register"],
  data() {
    return {
      isLoading: false,
      colNums: "row-cols-1 row-cols-lg-3",
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
  },
  mounted() {
    if (this.mobileCols && this.cols)
      this.colNums =
        "row-cols-" + this.mobileCols + " row-cols-lg-" + this.cols;
    else if (this.cols) this.colNums = "row-cols-" + this.cols;
  },
  methods: {
    submitMembership(membership) {
      if (this.register) this.$emit('saveRegistration')
      this.isLoading = true;
      let data;
      if (this.payment) {
        location.replace(this.payment);
      } else {
        data = {
          reseller_membership_id: membership.id,
          voucher_code: null,
        }
        data.membership = {
          name: membership.name,
          price: membership.price_upgrade || membership.price - membership.discount,
        }
        if(this.userData) {
          data.member_id = this.userData.id
          data.is_renew = this.resellerData && /RENEW|EXPIRED/.test(this.resellerData.is_membership_expired) ? 1 : 0
          if(this.upgrade) data.is_upgrade = 1
        }
        this.$store.dispatch("setMembershipPayment", data);
        this.isLoading = false;
      }
    },
  },
  watch: {
    membershipList(newValue) {
      return newValue;
    },
  },
};
</script>
<style scoped>
small >>> ul {
  padding-inline-start: 1rem;
}
.membership-card {
  position: relative;
  background: linear-gradient(to top right, var(--left-col, #d6b57e), var(--right-col, #e5d2a3));
  color: #fff;
  padding: 1rem;
  height: 100%;
  border-radius: .5rem;
}
.membership-card:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  mask: url('../../assets/img/Logomark_White_Background_Outline.svg') no-repeat 98% -0.25rem;
  mask-size: auto calc(100% - 3rem);
  border-radius: .5rem;
  opacity: .12;
}
.membership-card > * {
  position: relative;
}
</style>