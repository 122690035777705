<template>
  <b-modal
    id="modal-register-reseller"
    modal-class="p-0"
    :size="member ? 'm' : 'xl'"
    centered
    hide-header
    hide-footer
  >
    <i
      v-if="page > 1"
      class="icon-prev position-absolute mt-2 cursor-pointer"
      style="font-size: xx-large"
      @click="page -= 1"
    ></i>
    <button
      type="button"
      @click="$bvModal.hide('modal-register-reseller')"
      class="close"
      data-dismiss="modal"
    >
      <img src="@/assets/img/ic-close.svg" alt="" />
    </button>
    <h2 class="text-center">Business Partner Registration</h2>
    <!-- <div class="row justify-content-center">
      <div class="col-md-4">
        <p class="text-center mb-0">Get exclusive benefits &amp; instant profit<br/>on every purchase!</p>
      </div>
    </div> -->
    <hr />
    <b-form @submit.prevent="onSubmit" v-if="page == 1">
      <div class="row">
        <div :class="member ? 'col-md-12' : 'col-md-6'">
          <!-- // TODO: Email -->
          <MyInputFormText
            v-if="!member"
            id="email"
            label="Email"
            type="email"
            v-model="$v.email.$model"
            placeholder="Email"
            :classComponent="{
              'is-invalid-custom': $v.email.$error,
              'is-valid-custom': !$v.email.$invalid,
            }"
            :invalidMessage="[
              !$v.email.required ? 'Butuh email kamu nih' : null,
              !$v.email.email ? 'Masukan format email kamu' : null,
            ]"
            redAsterisk="true"
          />
          <!-- // TODO: Phone Number -->
          <MyInputFormText
            v-if="!member?.phone"
            id="phone-number"
            label="Phone Number"
            v-model="$v.phoneNumber.$model"
            placeholder="Phone Number"
            :classComponent="{
              'is-invalid-custom': $v.phoneNumber.$error,
              'is-valid-custom': !$v.phoneNumber.$invalid,
            }"
            :invalidMessage="[
              !$v.phoneNumber.required ? 'Butuh nomor telepon kamu nih' : null,
              !$v.phoneNumber.phonePattern
                ? 'Nomor telepon kamu tidak valid :('
                : null,
              !$v.phoneNumber.minLength && $v.phoneNumber.phonePattern
                ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${$v.phoneNumber.$params.minLength.min}.`
                : null,
              !$v.phoneNumber.maxLength && $v.phoneNumber.phonePattern
                ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${$v.phoneNumber.$params.maxLength.max}.`
                : null,
            ]"
            redAsterisk="true"
            phonePrefix="true"
          />
          <!-- // TODO: Full Name -->
          <MyInputFormText
            v-if="!member"
            id="full-name"
            label="Full Name"
            v-model="$v.fullName.$model"
            placeholder="Full Name"
            :classComponent="{
              'is-invalid-custom': $v.fullName.$error,
              'is-valid-custom': !$v.fullName.$invalid,
            }"
            :invalidMessage="[
              !$v.fullName.required ? 'Butuh nama lengkap kamu nih' : null,
              !$v.fullName.namePattern ? 'Masukin text yu!' : null,
            ]"
            redAsterisk="true"
          />
          <!-- // TODO: Birthday Date -->
          <MyInputFormText
            v-if="!member"
            id="birthday-date"
            label="Birthdate"
            type="date"
            v-model="$v.birthdayDate.$model"
            placeholder="Birthday Date"
            :classComponent="{
              'is-invalid-custom': $v.birthdayDate.$error,
              'is-valid-custom': !$v.birthdayDate.$invalid,
            }"
            :invalidMessage="[
              !$v.birthdayDate.required ? 'Butuh tanggal lahir kamu nih' : null,
              !$v.birthdayDate.maxValue
                ? 'Usia kamu belum mencapai 17 thn :('
                : null,
            ]"
            :max="getMaxBirthDate()"
            redAsterisk="true"
          />
          <!-- // TODO: Gender -->
          <!-- <MyInputFormSelect
            id="gender"
            label="Select Gender:"
            :options="genderList"
            v-model="gender"
            placeholder="Select Gender"
            v-if="
              !member
            "
          /> -->
          <div class="form-group" v-if="!member">
            <div class="row">
              <div class="col-md-6 d-flex align-items-center">
                <p class="mr-4 mb-0 text-nowrap">
                  <label>Gender <span class="text-danger">*</span></label>
                </p>
                <div class="styled-radio">
                  <label class="container d-flex align-items-center mb-2">
                    Male
                    <input
                      v-model="$v.gender.$model"
                      type="radio"
                      value="m"
                      name="gender"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="styled-radio">
                  <label class="container d-flex align-items-center mb-2">
                    Female
                    <input
                      v-model="$v.gender.$model"
                      type="radio"
                      value="f"
                      name="gender"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <p class="w-100 invalid-feedback d-block text-lg-right">
                  {{ $v.gender.$error ? "This field is required" : null }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-md-4">
              <!-- // TODO: Region -->
              <MyInputFormSelect
                id="province"
                label="Select Province *"
                :options="provinceList"
                v-model="$v.province.$model"
                placeholder="Select Province *"
                :classComponent="{
                  'is-invalid-custom': $v.province.$error,
                  'is-valid-custom': !$v.province.$invalid,
                }"
                :invalidMessage="[
                  !$v.province.required ? 'Butuh provinsi kamu nih' : null,
                ]"
                @input="getDistrict"
              />
            </div>
            <div class="col-md-4">
              <!-- // TODO: District -->
              <MyInputFormSelect
                :disabled="districtList.length == 0"
                id="district"
                label="Select District *"
                :options="districtList"
                v-model="$v.district.$model"
                placeholder="Select District *"
                :classComponent="{
                  'is-invalid-custom': $v.district.$error,
                  'is-valid-custom': !$v.district.$invalid,
                }"
                :invalidMessage="[
                  !$v.district.required ? 'Butuh daerah kamu nih' : null,
                ]"
                @input="getSubDistrict"
              />
            </div>
            <div class="col-md-4">
              <!-- // TODO: subDistrict -->
              <MyInputFormSelect
                :disabled="subDistrictList.length == 0"
                id="sub-district"
                label="Select Sub District *"
                :options="subDistrictList"
                v-model="$v.subDistrict.$model"
                placeholder="Select Sub District *"
                :classComponent="{
                  'is-invalid-custom': $v.subDistrict.$error,
                  'is-valid-custom': !$v.subDistrict.$invalid,
                }"
                :invalidMessage="[
                  !$v.subDistrict.required ? 'Butuh kecamatan kamu kamu nih' : null,
                ]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              <!-- // TODO: Address -->
              <MyInputFormText
                id="address"
                label="Address"
                v-model="$v.address.$model"
                placeholder="Address"
                :classComponent="{
                  'is-invalid-custom': $v.address.$error,
                  'is-valid-custom': !$v.address.$invalid,
                }"
                :invalidMessage="[
                  !$v.address.required ? 'Butuh alamat kamu nih' : null,
                ]"
                redAsterisk="true"
              />
            </div>
            <div class="col-5">
              <!-- // TODO: Postal Code -->
              <MyInputFormText
                id="postal-code"
                label="Postal Code"
                v-model="$v.postalCode.$model"
                placeholder="Postal Code"
                :maxlength="5"
                :classComponent="{
                  'is-invalid-custom': $v.postalCode.$error,
                  'is-valid-custom': !$v.postalCode.$invalid,
                }"
                :invalidMessage="[
                  !$v.postalCode.required ? 'Butuh kode postal kamu nih' : null,
                  !$v.postalCode.numeric && $v.postalCode.required
                    ? 'Masukan nomor yu!'
                    : null,
                  !$v.postalCode.minLength
                    ? `Kode postal kamu terlalu pendek nih, panjang teks minimum ${$v.postalCode.$params.minLength.min}`
                    : null,
                ]"
                redAsterisk="true"
              />
            </div>
            <!-- <div class="col-5">
              // TODO: Bank Reseller
              <MyInputFormSelect
                id="bank-reseller"
                label="Select Bank *"
                :options="bankResellerList"
                v-model="$v.bankReseller.$model"
                placeholder="Select Bank *"
                :classComponent="{
                  'is-invalid-custom': $v.bankReseller.$error,
                  'is-valid-custom': !$v.bankReseller.$invalid,
                }"
                :invalidMessage="[
                  !$v.bankReseller.required
                    ? 'Butuh bank yang kamu gunakan nih'
                    : null,
                ]"
              />
            </div>
            <div class="col-7">
              // TODO: Bank Account
              <MyInputFormText
                id="bank-Account"
                label="Bank Account"
                v-model="$v.bankAccount.$model"
                placeholder="Bank Account"
                :classComponent="{
                  'is-invalid-custom': $v.bankAccount.$error,
                  'is-valid-custom': !$v.bankAccount.$invalid,
                }"
                :invalidMessage="[
                  !$v.bankAccount.required
                    ? 'Butuh rekening bank kamu nih'
                    : null,
                  !$v.bankAccount.numeric && $v.bankAccount.required
                    ? 'Masukan nomor yu!'
                    : null,
                ]"
                redAsterisk="true"
              />
            </div> -->
            <div class="col-md-6" v-if="!member">
              <!-- // TODO: Password -->
              <MyInputFormText
                id="register-password"
                label="Password"
                type="password"
                v-model="$v.password.$model"
                placeholder="Password"
                :classComponent="{
                  'is-invalid-custom': $v.password.$error,
                  'is-valid-custom': !$v.password.$invalid,
                }"
                :invalidMessage="[
                  !$v.password.required ? 'Butuh kata sandi kamu nih' : null,
                  !$v.password.passwordPattern
                    ? 'Kata sandi kamu terlalu lemah, coba tambahin huruf dan angka yaa'
                    : null,
                  !$v.password.minLength
                    ? `Kata sandi kamu terlalu pendek nih, panjang teks minimum ${$v.password.$params.minLength.min}`
                    : null,
                ]"
                v-if="!this.member"
                redAsterisk="true"
              />
            </div>
            <div class="col-md-6" v-if="!member">
              <!-- // TODO: Confirm Password -->
              <MyInputFormText
                id="confirm-password"
                label="Confirm Password"
                type="password"
                v-model="$v.confirmPassword.$model"
                placeholder="Confirm Password"
                :classComponent="{
                  'is-invalid-custom': $v.confirmPassword.$error,
                  'is-valid-custom': !$v.confirmPassword.$invalid,
                }"
                :invalidMessage="[
                  !$v.confirmPassword.required
                    ? 'Butuh konfirmasi kata sandi kamu nih'
                    : null,
                  !$v.confirmPassword.sameAsPassword &&
                  $v.confirmPassword.required
                    ? 'Kata sandi kamu harus cocok ya ..'
                    : null,
                ]"
                v-if="!this.member"
                redAsterisk="true"
              />
            </div>
            <div class="col-12">
              <!-- // TODO: Referral Code -->
              <MyInputFormText
                id="referral"
                label="Referral Code (Optional)"
                type="text"
                v-model="$v.referral.$model"
                placeholder="Referral Code (Optional)"
              />
            </div>
          </div>
          <div class="alert alert-danger text-center" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </b-form>
    <!-- <b-form v-if="page == 2">
      <p class="text-center">
        We need to verify your information.<br />
        Please submit the documents below to process your application
      </p>
      <div class="row">
        <div :class="!member ? 'col-md-6' : 'col-12'">
          <MyInputFormFile
            icon="idcard"
            class="w-100"
            :value="idCard"
            :label="true"
            placeholder="Identification Card"
            :required="true"
            @input="idCard = $event"
          />
        </div>
        <div class="col">
          <MyInputFormFile
            icon="selfie"
            class="w-100"
            :value="selfie"
            :label="true"
            placeholder="Photo"
            :required="true"
            @input="selfie = $event"
          />
        </div>
      </div>
    </b-form> -->
    <!-- <b-form v-if="page == 3"> -->
    <b-form v-else-if="page == 2">
      <p class="text-center">
        We have {{ membershipList.length }} options you can choose!<br />
        Get business partner exclusive benefits today!
      </p>
      <Membership
        :membershipList="membershipList"
        :register="true"
        :cols="member ? 1 : null"
        @saveRegistration="onSubmit"
      />
    </b-form>
    <template v-if="page < 2">
      <hr />
      <div class="checkout-page d-flex justify-content-center">
        <div class="styled-checkbox mt-2" :class="{ 'opacity-2': page != 1 }">
          <label class="container-checkbox">
            I’ve read and agreed to Whizliz&apos;s
            <a
              href="/static/terms-condition-reseller"
              class="bold tnc-url"
              target="_blank"
              >Terms &amp; Conditions</a
            >
            <input type="checkbox" v-model="agreeTnc" :disabled="page != 1" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8">
          <button
            v-if="page == 1"
            type="button"
            class="btn btn-wz-primary btn-block"
            :disabled="$v.$invalid || !agreeTnc"
            @click="page = 2"
          >
            NEXT STEP
          </button>
          <button
            v-else-if="page == 2"
            type="button"
            class="btn btn-wz-primary btn-block"
            :disabled="!idCard || !selfie"
            @click="page = 3"
          >
            NEXT STEP
          </button>
          <button
            v-else-if="page == 3"
            type="button"
            class="btn btn-wz-primary btn-block"
            :disabled="false"
            @click="onSubmit"
          >
            REGISTER
          </button>
          <div class="text-center">
            <a
              v-if="!member"
              href="#"
              class="text-center text-small"
              @click="$bvModal.hide('modal-register-reseller')"
              v-b-modal.modalLogin
              >Already have a Whizliz Account?</a
            >
          </div>
        </div>
      </div>
    </template>
    <MembershipValidPayment @signUpReseller="signUpReseller" />
  </b-modal>
</template>
<script>
import { toaster, regex } from "@/_helpers";
import {
  resellerService,
  membershipService,
  addressService,
} from "@/_services";

import Membership from "@/components/resellers/Membership";
import MembershipValidPayment from "@/components/resellers/MembershipValidPayment";

import MyInputFormText from "@/components/form/MyInputFormText";
// import MyButtonSubmit from "@/components/form/MyButtonSubmit";
import MyInputFormSelect from "@/components/form/MyInputFormSelect";
// import MyInputFormFile from "@/components/form/MyInputFormFile";
import {
  email,
  numeric,
  required,
  requiredIf,
  sameAs,
  helpers,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import moment from "moment";

// Regex for Validation Start
const namePattern = helpers.regex("namePattern", regex.namePattern);
const passwordPattern = helpers.regex("passwordPattern", regex.passwordPattern);
const phonePattern = helpers.regex("phonePattern", regex.phonePattern);
// Regex for Validation End

export default {
  name: "ModalRegisterReseller",
  components: {
    Membership,
    MembershipValidPayment,
    MyInputFormText,
    // MyButtonSubmit,
    MyInputFormSelect,
    // MyInputFormFile,
  },
  validations: {
    email: {
      required: requiredIf(function () {
        return !this.member;
      }),
      email,
    },
    fullName: {
      required: requiredIf(function () {
        return !this.member;
      }),
      namePattern,
    },
    phoneNumber: {
      required,
      phonePattern,
      minLength: minLength(9),
      maxLength: maxLength(14),
    },
    birthdayDate: {
      required: requiredIf(function () {
        return !this.member;
      }),
      maxValue(date) {
        // res = 17 years ago
        let max = new Date().setFullYear(new Date().getFullYear() - 17) / 1000;
        max = moment.unix(max).toDate();
        date = new Date(date);
        if (date > max) return false;
        return true;
      },
    },
    gender: {
      required: requiredIf(function () {
        return !this.member;
      }),
    },
    password: {
      required: requiredIf(function () {
        return !this.member;
      }),
      minLength: minLength(6),
      passwordPattern,
    },
    confirmPassword: {
      required: requiredIf(function () {
        return !this.member;
      }),
      sameAsPassword: sameAs("password"),
    },
    address: {
      required,
    },
    district: {
      required,
    },
    subDistrict: {
      required,
    },
    province: {
      required,
    },
    postalCode: {
      required,
      numeric,
      minLength: minLength(5),
    },
    bankReseller: {},
    bankAccount: {
      numeric,
    },
    referral: {},
  },
  data() {
    return {
      idCard: null,
      selfie: null,
      referral: null,
      email: null,
      fullName: null,
      phoneNumber: null,
      birthdayDate: null,
      password: null,
      confirmPassword: null,
      gender: null,
      genderList: [
        { value: "M", text: "Male" },
        { value: "F", text: "Female" },
      ],
      agreeTnc: false,
      address: null,
      district: null,
      districtList: [],
      subDistrict: null,
      subDistrictList: [],
      province: null,
      provinceList: [],
      postalCode: null,
      bankReseller: null,
      bankResellerList: [],
      bankAccount: null,
      membershipList: [],
      page: 1,
      //! UMUM
      isLoading: false,
      errorMessage: null,
    };
  },
  computed: {
    member() {
      return this.$store.getters.getUserData;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
  },
  mounted() {
    this.getProvinceList();
    this.getBankResellerList();
    this.getListMembership();
    const isReferral = /\/register-bus-partner/.test(this.$route.path);
    if (isReferral) this.referral = this.$route.params.slug;
    if (this.resellerData) this.setRegistrationForm(this.resellerData);
  },
  methods: {
    setRegistrationForm(data) {
      this.province = data.region.id;
      this.getDistrict(this.province, false);
      this.district = data.district.id;
      this.getSubDistrict(this.district, false);
      this.subDistrict = data.township.id;
      this.address = data.address;
      this.postalCode = data.postal_code;
      this.bankReseller = data.bank_info.split("-")[0];
      this.bankAccount = data.bank_account;
      this.selfie = data.image_idcard_hold_url;
      this.idCard = data.image_idcard_url;
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      // console.log(this.$v.$invalid);
      const agree = this.agreeTnc;
      if (this.$v.$invalid) {
        toaster.make("Please Fill the Form Correctly", "danger");
        this.isLoading = false;
        return;
      } else if (agree) {
        try {
          const resellerData = {
            device_model: "WEB",
            deviceType: "WEB",
            newsletter: true,
            google_id: "",
            fb_id: "",
            address: this.address,
            district_id: this.district,
            township_id: this.subDistrict,
            region_id: this.province,
            village_id: null,
            postal_code: this.postalCode,
            bank_reseller: this.bankReseller ?? '',
            bank_account: this.bankAccount ?? '',
          };
          if (this.member) {
            resellerData.email = this.member.email;
            resellerData.full_name = this.member.full_name;
            resellerData.password = this.password;
            resellerData.confirm_password = this.confirmPassword;
            resellerData.phone = /\+62/.test(this.phoneNumber) ? this.phoneNumber : "+62" + this.phoneNumber;
            resellerData.gender = this.member.gender ? this.member.gender : "";
            resellerData.birth_date = this.member.birth_date;
          } else {
            resellerData.email = this.email;
            resellerData.full_name = this.fullName;
            resellerData.password = this.password;
            resellerData.confirm_password = this.confirmPassword;
            resellerData.phone = /\+62/.test(this.phoneNumber) ? this.phoneNumber : "+62" + this.phoneNumber;
            resellerData.gender = this.gender ? this.gender : "";
            resellerData.birth_date = this.birthdayDate;
            resellerData.referral_code = this.referral ?? '';
          }
          this.$store.dispatch("setResellerRegistration", {
            data: [resellerData],
            image_idcard: this.idCard,
            image_idcard_hold: this.selfie,
          });
          // const form = new FormData();
          // form.append("data", JSON.stringify([resellerData]));
          // form.append("image_idcard", this.idCard);
          // form.append("image_idcard_hold", this.selfie);

          this.isLoading = false;
        } catch (error) {
          console.error(error);
          this.isLoading = false;
        }
      } else {
        toaster.make(
          "You haven't agreed to our T&C, please confirm.",
          "warning"
        );
        this.isLoading = false;
      }
    },
    async signUpReseller(event) {
      try {
        let resellerRegistration = event;
        const isRenew = this.$store.getters.getMembershipPayment.is_renew
        resellerRegistration.data[0].is_renew = isRenew
        const form = new FormData();
        form.append("data", JSON.stringify(resellerRegistration.data));
        form.append("image_idcard", resellerRegistration.image_idcard);
        form.append("image_idcard_hold", resellerRegistration.image_idcard_hold);
        const response = await this.$api.reseller.signUpReseller(form);
        if (response.status === 200) {
          let next2days = new Date();
          next2days.setDate(next2days.getDate() + 2);
          next2days = moment(String(next2days))
            .utc()
            .format("ddd, DD MMM YYYY HH:mm:ss UTC")
            .toString();
          document.cookie =
            "associatePaymentUrl=" +
            response.data.data.redirect_url +
            "; expires=" +
            next2days +
            "; path=/";
          const url = response.data.data.redirect_url || response.data.data.redirectUrl;
          location.replace(url);
        } else toaster.make(response.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async getProvinceList() {
      this.provinceList = [];
      try {
        const response = await addressService.getProvinceList();
        if (response.status === 200) {
          this.provinceList = this.setMyInputFormSelectOptionsAdress(
            response.data.data
          );
        } else {
          this.provinceList = [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getDistrict(id, clear = true) {
      if (this.province != null) {
        if (clear) {
          this.district = null;
          this.subDistrict = null;
        }
        this.districtList = [];
        this.subDistrictList = [];
        try {
          const response = await addressService.getDistrictList(id);
          if (response.status === 200) {
            this.districtList = this.setMyInputFormSelectOptionsAdress(
              response.data.data
            );
          } else {
            this.districtList = [];
            this.subDistrictList = [];
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getSubDistrict(id, clear) {
      if (this.district != null) {
        if (clear) this.subDistrict = null;
        this.subDistrictList = [];
        try {
          const response = await addressService.getSubDistrictList(id);
          if (response.status === 200) {
            this.subDistrictList = this.setMyInputFormSelectOptionsAdress(
              response.data.data
            );
          } else {
            this.subDistrictList = [];
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    setMyInputFormSelectOptionsAdress(list) {
      let resultOptions = [];
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        resultOptions.push({ value: element.id, text: element.name });
      }
      return resultOptions;
    },
    async getBankResellerList() {
      this.bankResellerList = [];
      try {
        const response = await resellerService.getBankResellerList();
        if (response.status === 200) {
          this.bankResellerList = this.setMyInputFormSelectOptionsBankReseller(
            response.data.data
          );
        } else {
          this.bankResellerList = [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getListMembership() {
      this.membershipList = [];
      try {
        const res = await membershipService.getListMembership();
        if (res.status === 200) this.membershipList = res.data.data;
        else this.membershipList = [];
      } catch (e) {
        console.error(e);
      }
    },
    setMyInputFormSelectOptionsBankReseller(list) {
      let resultOptions = [];
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        resultOptions.push({ value: element.id, text: element.name });
      }
      return resultOptions;
    },
    getMaxBirthDate() {
      const res = new Date().setFullYear(new Date().getFullYear() - 17) / 1000;
      return moment.unix(res).format("YYYY-MM-DD").toString();
    },
  },
  watch: {
    "$route.path"(val) {
      if (!val) return
      if (/\/register-bus-partner/.test(val) && this.$route.params.slug) this.referral = this.$route.params.slug;
    },
    member(val) {
      if (!val) return;
      this.phoneNumber = /\+62/.test(this.member.phone)
        ? this.member.phone.replace(/\+62/, "")
        : this.member.phone;
    },
    //   province(newValue) {
    //     this.getDistrict(newValue);
    //   },
    //   district(newValue) {
    //     this.getSubDistrict(newValue);
    //   },
  },
};
</script>
<style scoped>
:is(.checkout-page, #modalRegister, #modalLogin) .styled-checkbox label {
  font-size: 12px;
  font-weight: initial;
  text-transform: initial;
}
:is(.checkout-page, #modalRegister, #modalLogin) .checkmark {
  top: -1px;
}
h2 {
  padding: 0.5rem 0;
  font-size: 1.5rem;
}
</style>