<template>
  <b-modal id="modal-membership-payment" modal-class="p-0" size="lg" centered hide-header hide-footer>
    <h2 class="mb-4 text-center" style="font-size: 1.5rem;">Select Payment</h2>
    <div class="payment-method-box" v-if="payments.length">
      <div class="styled-radio" v-for="(payment, i) in payments" :key="i">
        <label class="container align-items-center clearfix">
          <div class="float-left">
            <input type="radio" name="radio" @click="paymentMethod = payment.value" />
            <span class="checkmark" style="top: 5px!important;"></span>
            <p class="mt-2 mb-0" style="line-height: 16px;"><b>{{payment.name}}</b>
            </p>
          </div>
          <div class="payment-method-img-container float-right" v-if="payment.image_url">
            <b-img :src="payment.image_url" alt="" />
          </div>
          <!-- <b-img class="float-right pt-1 mw-100" v-if="payment.image_url" :src="payment.image_url" /> -->
        </label>
      </div>
    </div>
    <hr/>
    <div class="text-small d-flex align-items-center">
      <div>
        <span class="text-gold">
          Membership Plan<br/>
          Membership Price
        </span>
      </div>
      <div class="pr-1">
        <span class="text-gold">
          :<br/>:
        </span>
      </div>
      <div class="bold">
        {{membership.name}}<br/>
        {{$helpers.format.price(membership.price)}}
      </div>
      <button type="button" class="btn btn-wz-primary ml-auto d-none d-lg-block" @click="submitMembership">
        Pay Now 
        <font-awesome v-if="isLoading" icon="spinner" spin />
      </button>
    </div>
    <button type="button" class="btn btn-wz-primary btn-block mt-2 d-lg-none" @click="submitMembership">
      Pay Now 
      <font-awesome v-if="isLoading" icon="spinner" spin />
    </button>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";
import { membershipService } from "@/_services";

export default {
  name: "MembershipValidPayment",
  data() {
    return {
      payments: [],
      paymentMethod: null,
      isLoading: false,
      membership: {
        name: '',
        price: 0
      },
    }
  },
  computed: {
    checkout() {
      return this.$store.getters.getMembershipPayment
    },
  },
  methods: {
    // logic
    async getValidPayment(data) {
      try {
        const body = {
          reseller_membership_id: data.reseller_membership_id,
          voucher_code: null,
          is_renew: data.is_renew || 0
        }
        const response = await membershipService.getValidPayment(body)
        this.payments = response.data.data
      } catch (e) {
        console.error(e)
      }
    },
    async submitMembership() {
      this.isLoading = true;
      try {
        const resellerRegistration = this.$store.getters.getResellerRegistration
        if(resellerRegistration && this.paymentMethod) {
          resellerRegistration.data[0].reseller_membership_id = this.checkout.reseller_membership_id,
          resellerRegistration.data[0].voucher_code = null,
          resellerRegistration.data[0].payment_type = this.paymentMethod
          this.$emit('signUpReseller', resellerRegistration)
        } else if (this.paymentMethod) {
          const body = {
            member_id: this.checkout.member_id,
            reseller_membership_id: this.checkout.reseller_membership_id,
            voucher_code: null,
            payment_type: this.paymentMethod,
          }
          if(!this.checkout.is_upgrade) body.is_renew = this.checkout.is_renew
          const response = this.checkout.is_upgrade ? await membershipService.upgradeMembership(body) :
            await membershipService.checkoutMembership(body)
          if (response.status === 200) {
            toaster.make(response.data.message, "success")
            this.changeWindowLocation(response.data.data.redirect_url);
          } else toaster.make(response.data.message, "danger")
        } else toaster.make("Please select a payment method", "danger")
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    changeWindowLocation(redirect_url) {
      if (redirect_url) window.location.href = redirect_url;
    },
    // view script
  },
  watch: {
    checkout(val) {
      this.membership = val.membership
      this.getValidPayment(val)
    }
  }
}
</script>
