<template>
  <b-modal
    id="modalForgotPassword"
    modal-class="p-0"
    centered
    hide-header
    hide-footer
    @hidden="onModalHide"
  >
    <button
      type="button"
      @click="$bvModal.hide('modalForgotPassword')"
      class="close"
      data-dismiss="modal"
    >
      <img src="@/assets/img/ic-close.svg" alt="" />
    </button>
    <form @submit.prevent="handleSubmit">
      <h2>{{ isJuene ? "Set New Password" : "Forgot Password" }}</h2>
      <p class="my-4">
        {{
          isJuene
          ? "Enter your registered Juene Jewelry email address below and we'll send you a link to set new password"
          : "Enter your email address below and we will send you a link to reset your password"
        }}
      </p>
      <div class="input-line">
        <input
          type="email"
          v-model="email"
          placeholder="Your email address here..."
          name="email"
          required
        />
        <span class="highlight"></span>
        <span class="bar"></span>
        <label></label>
      </div>

      <div
        class="alert alert-danger text-center font-14px"
        v-if="isSuccess == false"
      >
        {{
          isJuene
          ? "This juene email address are not found in our database, please recheck your input"
          : "This user email address are not found in our database, please recheck your input or create a new account"
        }}
      </div>
      <div
        class="alert alert-success text-center font-14px"
        v-else-if="isSuccess == true"
      >
        A link to set your new password has been sent to <b>{{ email }}</b>
        <br />
        Please check your spam folder if the email is not in your inbox.
      </div>

      <div class="d-inline-flex justify-content-end w-100">
        <h3 class="roboto12 mt-3 mr-3">
          <a
            href="#"
            data-toggle="modal"
            data-target="modalResetPassword"
            @click="
              $bvModal.show('modalLogin');
              $bvModal.hide('modalForgotPassword');
            "
            >Back to Sign In</a
          >
        </h3>
        <button class="btn btn-wz-proceed">
          Send <font-awesome v-if="isLoading" icon="spinner" spin />
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: "ModalForgotPassword",
  data() {
    return {
      email: "",
      isSuccess: null,
      isLoading: false,
    };
  },
  computed: {
    isJuene() {
      return this.$store.getters.getIsJuene;
    }
  },
  methods: {
    onModalHide() {
      this.email = this.isSuccess = null; //* Clear email & isSuccess state on modal close / hide
      this.$store.dispatch('setIsJuene', false) //* Set isJuene store state to false on modal close / hide
    },
    async handleSubmit() {
      try {
        if (!this.email) return;
        this.isLoading = true;
        const data = { email: this.email };
        const res = await (this.isJuene ? this.$api.user.setJuenePassword(data) : this.$api.user.forgotPassword(data));
        if (![200, 404].includes(res.status))
          return this.$helpers.toaster.make(res.data.message, "danger"); //* If response isn't either of success(200) / email not found(404)
        this.isSuccess = res.status === 200 ? true : false;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.error(e);
      }
    },
  },
};
</script>