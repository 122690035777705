<template>
  <section class="footer">
    <footer class="d-none d-lg-block">
      <!-- //* Subscribe Email Temporary Take Out -->
      <!-- <div class="top-footer">
        <div class="row">
          <div class="col text-right">
            <form @submit.prevent="subscribeEmail">
              <label class="bold">Get our latest news &amp; promotions</label>
              <input
                type="email"
                name="email"
                class="w-25"
                placeholder="Enter Email Address"
                v-model="email"
                required
              />
              <button class="btn btn-wz-gold">
                SUBSCRIBE
                <font-awesome v-if="isLoadingSubscribe" icon="spinner" spin />
              </button>
            </form>
          </div>
        </div>
      </div> -->
      <div class="middle-footer">
        <div class="row">
          <!-- 1st Row : Footer Left Menu  -->
          <div class="col">
            <h6>ABOUT WHIZLIZ</h6>
            <ul>
              <li v-for="(leftFooter, index) in leftFooters" :key="index">
                <router-link
                  v-if="leftFooter.new_tab == false"
                  :to="leftFooter.page_url"
                >
                  {{ leftFooter.name }}
                </router-link>
                <a v-else target="_blank" :href="leftFooter.page_url">
                  {{ leftFooter.name }}
                </a>
              </li>
            </ul>
          </div>
          <!-- 2nd Row : Footer Middle Menu -->
          <div class="col">
            <h6 class="footer-col-head">About Whizliz</h6>
            <ul>
              <li v-for="(middleFooter, index) in middleFooters" :key="index">
                <router-link
                  v-if="middleFooter.new_tab == false"
                  :to="middleFooter.page_url"
                >
                  {{ middleFooter.name }}
                </router-link>
                <a v-else target="_blank" :href="middleFooter.page_url">
                  {{ middleFooter.name }}
                </a>
              </li>
              <li>
                <router-link to="/faqs">FAQs</router-link>
              </li>
            </ul>
          </div>
          <!-- 3rd Row : Footer Right Menu -->
          <div class="col">
            <h6 class="footer-col-head">About Whizliz</h6>
            <ul>
              <li v-for="(rightFooter, index) in rightFooters" :key="index">
                <router-link
                  v-if="rightFooter.new_tab == false"
                  :to="rightFooter.page_url"
                >
                  {{ rightFooter.name }}
                </router-link>
                <a v-else target="_blank" :href="rightFooter.page_url">
                  {{ rightFooter.name }}
                </a>
              </li>
            </ul>
          </div>
          <!-- 4th Row : Whizliz Help Row -->
          <div class="col">
            <h6>NEED HELP?</h6>
            <ul>
              <li>
                <div class="text-white customer-care-label-footer">Customer Care</div>
              </li>
              <li>
                <a href="mailto:cs@whizliz.com" target="_blank"
                  >Email : cs@whizliz.com</a
                >
              </li>
              <li>
                <a href="https://wa.me/62818211863" target="_blank"
                  >Phone : +62 818-211863</a
                >
              </li>
              <li>
                <router-link id="contact-us" :to="$route.path" v-b-modal.modal-contact-us>
                  Contact us
                </router-link>
                <ModalContactUs />
              </li>
            </ul>
          </div>
          <!-- 5th Row : Download Apps Menu -->
          <div class="col">
            <h6>DOWNLOAD OUR APPS HERE</h6>
            <ul>
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.linowalden" target="_blank">
                  <img width="150" src="@/assets/img/google-play-download.png" alt="" />
                </a>
              </li>
              <li class="mt-2">
                <a href="https://apps.apple.com/us/app/id1513866635" target="_blank">
                  <img width="150" src="@/assets/img/app-store-download.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
          <!-- <div class="col">
            <h6>HELP</h6>
            <ul>
              <li>
                <a href=""> Terms & Condition</a>
              </li>
              <li>
                <a href="">Secure Shopping</a>
              </li>
              <li>
                <a href="">How to Order</a>
              </li>
              <li>
                <a href="">How to Sell Back </a>
              </li>
            </ul>
          </div>
          <div class="col">
            <h6>WHY WHIZLIZ</h6>
            <ul>
              <li>
                <a href=""> Money back Guarantee </a>
              </li>
              <li>
                <a href="">Our Service </a>
              </li>
              <li>
                <a href="">Whizliz Benefits </a>
              </li>
            </ul>
          </div>
          <div class="col">
            <h6>QUICK LINKS</h6>
            <ul>
              <li>
                <a href=""> Promotion</a>
              </li>
              <li>
                <a href="">Wish List</a>
              </li>
              <li>
                <a href="">Contact Us </a>
              </li>
            </ul>
          </div>
          <div class="col">
            <h6>NEED HELP?</h6>
            <ul>
              <li>
                <a href=""> Liz Point</a>
              </li>
              <li>
                <a href=""> Return Online</a>
              </li>
              <li>
                <a href=""> Email: cs@whizliz.com </a>
              </li>
              <li>
                <a href=""> Phone: +62-818-211863 </a>
              </li>
            </ul>
          </div> -->
        </div>
        <div class="footer-logo">
          <div class="row">
            <div class="col d-flex align-items-center">
              <span>DISCOVER MORE</span>
              <a href="https://instagram.com/whizliz.id"
                ><i class="fab fa-instagram fa-lg"></i
              ></a>
              <a href="https://www.youtube.com/channel/UC8XGA_zH3vNinQZMNjiR82A"
                ><i class="fab fa-youtube fa-lg"></i
              ></a>
            </div>
            <div class="col">
              <img src="@/assets/img/logo-payment.png" alt="" />
            </div>
          </div>
        </div>
        <div class="copyright">
          <div class="row">
            <div class="col">
              <span
                >Copyright &copy; {{ new Date().getUTCFullYear() }} Whizliz.com. All rights reserved
              </span>
            </div>
            <div class="col text-right">
              <img src="@/assets/img/logo-certificate.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </footer>
    <footer class="d-lg-none">
      <div class="container-fluid">
        <!-- <div class="row p-20">
          <div class="col">
            <i class="icon-shopping-bag-check"></i> How To Order
          </div>
          <div class="col">
            <i class="icon-delivery-truck"></i> Free Delivery
          </div>
        </div> -->
        <!-- //* Subscribe Email Temporary Take Out -->
        <!-- <form class="pt-3" @submit.prevent="subscribeEmail">
          <label class="text-white bold">Get our latest news &amp; promotions</label>
          <div class="input-group mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="Enter Your Email"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              v-model="email"
              required
            />
            <div class="input-group-append">
              <button class="btn btn-wz-gold" type="button">
                SUBSCRIBE
                <font-awesome v-if="isLoadingSubscribe" icon="spinner" spin />
              </button>
            </div>
          </div>
        </form> -->
        <button class="accordion mt-3 py-2 pl-3 rounded" @click="togglePanel">
          About Whizliz
        </button>
        <div class="panel">
          <ul class="py-3">
            <li v-for="(leftFooter, index) in leftFooters" :key="index">
              <router-link
                v-if="leftFooter.new_tab == false"
                :to="leftFooter.page_url"
              >
                {{ leftFooter.name }}
              </router-link>
              <a v-else target="_blank" :href="leftFooter.page_url">
                {{ leftFooter.name }}
              </a>
            </li>
            <li>
              <router-link to="/faqs">FAQs</router-link>
            </li>
            <li>
              <router-link :to="$route.path" v-b-modal.modal-contact-us>Contact Us</router-link>
            </li>
          </ul>
        </div>
        <div class="row pt-3">
          <label class="text-white col-12 bold">Download our apps here:</label>
          <a href="https://play.google.com/store/apps/details?id=com.linowalden" target="_blank" class="col-6">
            <img src="@/assets/img/google-play-download.png" alt="" />
          </a>
          <a href="https://apps.apple.com/us/app/id1513866635" target="_blank" class="col-6">
            <img src="@/assets/img/app-store-download.png" alt="" />
          </a>
        </div>
      </div>
      <div class="bg-BAB5B1 p-20 mt-3">
        <div class="row mb-2">
          <div class="col d-flex align-items-center">
            <h3 class="roboto12 bold mb-1 mr-4">DISCOVER MORE</h3>
            <a
              class="text-black pl-2"
              href="https://www.instagram.com/whizliz.id"
              ><i class="fab fa-instagram fa-lg"></i
            ></a>
            <a
              class="text-black pl-2"
              href="https://www.youtube.com/channel/UC8XGA_zH3vNinQZMNjiR82A"
              ><i class="fab fa-youtube fa-lg"></i
            ></a>
          </div>
        </div>
        <img class="blend-dark" src="@/assets/img/payment-all1.png" alt="" />
        <img class="blend-dark" src="@/assets/img/payment-all2.png" alt="" />
        <img class="blend-dark" src="@/assets/img/payment-all3.png" alt="" />
      </div>
      <div class="text-center pb-1 mt-3">
        <img src="@/assets/img/certificate.png" alt="" />
        <h3 class="roboto12 mt-2 text-white">
          Copyright &copy; {{ new Date().getUTCFullYear() }} Whizliz.com. All rights reserved
        </h3>
      </div>
    </footer>
  </section>
</template>

<script>
const ModalContactUs = () => import('../modals/ModalContactUs.vue');
import { toaster } from "@/_helpers";

export default {
  name: "Footer",
  components: {
    ModalContactUs,
  },
  data() {
    return {
      email: "",
      leftFooters: [],
      middleFooters: [],
      rightFooters: [],
      isLoadingSubscribe: false,
    };
  },
  mounted() {
    this.getFooterMenu();
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  methods: {
    async getFooterMenu() {
      try {
        const res = await this.$api.cms.getFooterMenu()
        if (res.status === 200) {
          const data = res.data.data
          this.leftFooters = data.left;
          this.middleFooters = data.middle;
          this.rightFooters = data.right;
        } else console.log(res.data.message)
      } catch (error) {
        console.error(error)
      }
    },
    togglePanel(event) {
      event.target.classList.toggle("active");
      const panel = event.target.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    },
    async subscribeEmail() {
      this.isLoadingSubscribe = true;
      try {
        const data = { email_address: this.email };
        const res = await this.$api.user.subscribeEmail(data)
        if (res.status === 200) toaster.make("Thank you for subscribing", "success")
        else toaster.make("Oops, something went wrong", "danger")
        this.isLoadingSubscribe = false;
      } catch (error) {
        console.error(error)
        this.isLoadingSubscribe = false;
      }
    },
  },
};
</script>
<style scoped>
.label-download-apps {
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0 20px 0 0 ;
  color: #fff;
  font-weight: bold;
}
</style>