<template>
  <section class="header" :class="{'header-fixed' : localFixed}">
    <header class="d-lg-none d-block header head-white" :class="isCustomTheme ? 'header-custom-theme' : ''">
      <div v-for="(menu, i) in topmenu.filter(el => el.show_type == 'MARQUEE').slice(0, 1)" :key="i">
        <marquee class="topmenu-marquee px-3" :class="{ 'bg-transparent': isCustomTheme }">
          <UrlHandler :url="menu.link_url" :class="menuTextColor">{{menu.text}}</UrlHandler>
        </marquee>
      </div>
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <div class="row w-auto100 justify-content-inherit">
            <div class="col-auto d-inline-flex align-items-center">
              <fawesome-pro variant="fal" icon="bars" size="lg" v-b-toggle.sidebar-menu :class="isCustomTheme ? 'text-white' : 'text-black'" />
              <router-link to="/" class="bg-transparent">
                <img
                  src="@/assets/img/logo-black.svg"
                  class="ml-3"
                  width="100"
                  :class="{ 'filter-invert-1': isCustomTheme }"
                  alt=""
                />
              </router-link>
            </div>
            <div class="col-auto pl-0">
              <ul class="account-menu-responsive">
                <li>
                  <form class="form-search" @submit.prevent="search(searchData)">
                    <img
                      src="@/assets/img/search.svg"
                      style="padding-right: 6px; width: 28px; height: 28px"
                      :class="{ 'filter-invert-1': isCustomTheme }"
                      alt=""
                    />
                    <div>
                      <History :data="history" @search="search" />
                      <fawesome-pro icon="chevron-left" />
                    </div>
                  </form>
                </li>
                <li>
                  <a
                    v-if="userData"
                    href="#"
                    v-b-modal.side-wishlist
                  >
                    <img
                      id="my-whizliz-responsive"
                      src="@/assets/img/my-whizliz.svg"
                      alt=""
                      style="padding-right: 6px; width: 30px; height: 30px"
                      :class="{ 'filter-invert-1': isCustomTheme }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-whizliz-hover.svg'),
                          'my-whizliz-responsive'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-whizliz.svg'),
                          'my-whizliz-responsive'
                        )
                      "
                    />
                  </a>
                  <a v-else href="#" v-b-modal="'modalLogin'">
                    <img
                      id="my-whizliz-responsive"
                      src="@/assets/img/my-whizliz.svg"
                      alt=""
                      style="padding-right: 6px; width: 30px; height: 30px"
                      :class="{ 'filter-invert-1': isCustomTheme }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-whizliz-hover.svg'),
                          'my-whizliz-responsive'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-whizliz.svg'),
                          'my-whizliz-responsive'
                        )
                      "
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    v-b-modal.side-cart
                    class="position-relative"
                    v-if="userData"
                  >
                    <span class="cart-number">{{
                      $store.getters.getShoppingBag.length
                    }}</span>
                    <img
                      id="my-cart-responsive"
                      src="@/assets/img/my-cart.svg"
                      alt=""
                      style="padding-right: 6px; width: 30px; height: 30px"
                      :class="{ 'filter-invert-1': isCustomTheme }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-cart-hover.svg'),
                          'my-cart-responsive'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-cart.svg'),
                          'my-cart-responsive'
                        )
                      "
                    />
                  </a>
                  <a href="#" v-else v-b-modal="'modalLogin'" class="btn-cart">
                    <img
                      id="my-cart-responsive"
                      src="@/assets/img/my-cart.svg"
                      alt=""
                      style="padding-right: 6px; width: 30px; height: 30px"
                      :class="{ 'filter-invert-1': isCustomTheme }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-cart-hover.svg'),
                          'my-cart-responsive'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-cart.svg'),
                          'my-cart-responsive'
                        )
                      "
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <div v-if="loaded">
      <b-sidebar
        id="sidebar-menu"
        title="Menu"
        ref="sidebar"
        backdrop
        lazy
        @hidden="closeSidebars()"
      >
        <div id="" class="d-none" v-b-toggle.sidebar-category v-b-toggle.sidebar-brand v-b-toggle.sidebar-collection></div>
        <b-list-group>
          <router-link to="/products/new-in">
            <b-list-group-item>What's New</b-list-group-item>
          </router-link>
          <b-list-group-item v-b-toggle.sidebar-category
            >Category<span class="float-right pt-1 fa fa-angle-right"></span
          ></b-list-group-item>
          <b-list-group-item v-b-toggle.sidebar-brand>
            Shop by Brand<span class="float-right pt-1 fa fa-angle-right"></span>
          </b-list-group-item>
          <b-list-group-item v-b-toggle.sidebar-collection
            >Find Your Style<span
              class="float-right pt-1 fa fa-angle-right"
            ></span
          ></b-list-group-item>
          <!-- <router-link to="/promo">
            <b-list-group-item>Promotion</b-list-group-item>
          </router-link> -->
          <b-list-group-item v-b-toggle.sidebar-promotion
            >Promotion<span
              class="float-right pt-1 fa fa-angle-right"
            ></span
          ></b-list-group-item>
          <router-link to="/our-review">
            <b-list-group-item>Our Reviews</b-list-group-item>
          </router-link>
          <b-list-group-item v-b-toggle.sidebar-why
            >Why Whizliz<span class="float-right pt-1 fa fa-angle-right"></span
          ></b-list-group-item>
          <b-list-group-item v-for="(adt, i) in adtCategory" :key="i" :to="'/products/' + adt.slug">
            {{adt.name}}
          </b-list-group-item>
          <template v-if="userData">
            <b-list-group-item v-b-toggle.sidebar-profile>
              Profile<span class="float-right pt-1 fa fa-angle-right"></span>
            </b-list-group-item>
            <!-- <b-list-group-item variant="secondary" class="py-1"
              >Other</b-list-group-item
            >
            <b-list-group-item v-b-modal="'how-to-order'"
              ><i class="icon-shopping-bag-check"></i> How to
              Order</b-list-group-item
            > -->
            <b-list-group-item @click="logout()"
              >Logout</b-list-group-item
            >
          </template>
          <b-list-group-item v-b-modal="'modalLogin'" v-else
            >Login</b-list-group-item
          >
          <b-list-group-item v-for="(menu, i) in [...topmenu.filter(el => el.show_type == 'MARQUEE').slice(1), ...topmenu.filter(el => el.show_type == 'MENU' )]" :key="`adtmenu-${i}`" class="list-group-item-topmenu">
            <UrlHandler :url="menu.link_url">{{menu.text}}</UrlHandler>
          </b-list-group-item>
        </b-list-group>
      </b-sidebar>
      <b-sidebar id="sidebar-category" title="Category" ref="sidebar" lazy>
        <template #header-close
          ><span class="fa fa-arrow-left fa-sm"></span
        ></template>
        <b-list-group>
          <!-- By Price -->
          <b-list-group-item variant="secondary" class="py-1"
            >By Price</b-list-group-item
          >
          <router-link to="/products/under5m">
            <b-list-group-item>Under 5M</b-list-group-item>
          </router-link>
          <router-link to="/products/under15m">
            <b-list-group-item>Under 15M</b-list-group-item>
          </router-link>
          <router-link to="/products/under25m">
            <b-list-group-item>Under 25M</b-list-group-item>
          </router-link>

          <!-- By Category -->
          <template v-for="category in categories">
            <template v-for="cat in category.sub_category">
              <b-list-group-item
                variant="secondary"
                class="py-1"
                :key="'categories-' + category.id + cat.id"
                >{{ cat.name }}
              </b-list-group-item>
              <router-link
                v-for="subCat in cat.sub_category"
                :key="subCat.id"
                :to="
                  simpleSlug(category.slug, cat.slug, subCat.slug, subCat.id)
                "
              >
                <b-list-group-item>{{ subCat.name }}</b-list-group-item>
              </router-link>
            </template>
          </template>
        </b-list-group>
        <div class="px-3 py-2">
          <b-img-lazy :src="banner1.image_url" alt="" fluid />
          <b-img-lazy :src="banner2.image_url" alt="" fluid />
        </div>
      </b-sidebar>
      <!-- Sidebar shop by brand -->
      <b-sidebar id="sidebar-brand" title="Shop by brand" ref="sidebarBrand" lazy>
        <template #header-close>
          <span class="fa fa-arrow-left fa-sm"></span>
        </template>
        <div class="text-center mt-4" v-if="isGroupedBrandsLoading">
          <font-awesome icon="spinner" size="5x" spin />
        </div>
        <template v-else>
          <b-list-group>
            <router-link to="/shop-by-brands" class="bold">
              <b-list-group-item>View More</b-list-group-item>
            </router-link>
            <!-- Brands group category -->
            <template v-for="group in groupedBrands">
              <b-list-group-item
                variant="secondary"
                class="py-1"
                :key="`brand-category-${group.id}`"
              >
                {{ group.name }}
              </b-list-group-item>
              <!-- Brands per group category -->
              <router-link
                v-for="brand in group.brands"
                :key="`brand-${brand.id}`"
                :to="`/shop-by-brands/${brand.slug}-official-store-${brand.id}`"
              >
                <b-list-group-item>{{ brand.name }}</b-list-group-item>
              </router-link>
            </template>
          </b-list-group>
          <div class="p-3">
            <BrandShowcaseHeader :brand="autoBrand" v-if="autoBrand"/>
          </div>
        </template>
      </b-sidebar>
      <b-sidebar id="sidebar-collection" title="Find Your Style" ref="sidebar" lazy>
        <template #header-close
          ><span class="fa fa-arrow-left fa-sm"></span
        ></template>
        <b-list-group>
          <router-link
            v-for="(collection, i) in collections"
            :key="i"
            :to="`/products/collection/${$helpers.format.slug(`${collection.name} ${collection.id}`)}`"
          >
            <b-list-group-item>{{ collection.name }}</b-list-group-item>
          </router-link>
          
          <div class="px-3 py-2">
            <b-img-lazy :src="autoCollection && autoCollection.image_mobile" :alt="autoCollection.name" fluid />
          </div>
        </b-list-group>
      </b-sidebar>
      <b-sidebar id="sidebar-promotion" title="Promotion" ref="sidebarPromotion" lazy>
        <template #header-close><span class="fa fa-arrow-left fa-sm"></span></template>
        <b-list-group>
          <router-link to="/promo" class="bold">
            <b-list-group-item>View More</b-list-group-item>
          </router-link>
          <router-link
            v-for="(promo, i) in promos"
            :key="i"
            :to="'/products/' + promo.slug"
          >
            <b-list-group-item>{{ promo.name }}</b-list-group-item>
          </router-link>
          <div class="px-3 py-2" v-if="autoPromo">
            <b-img-lazy :src="autoPromo.image_responsive_src" :alt="autoPromo.name" fluid />
          </div>
        </b-list-group>
      </b-sidebar>
      <b-sidebar v-if="userData" id="sidebar-profile" ref="sidebar" no-header lazy>
        <ProfileNavbar visible />
      </b-sidebar>
      <b-sidebar id="sidebar-why" title="Why Whizliz" ref="sidebar" lazy>
        <template #header-close
          ><span class="fa fa-arrow-left fa-sm"></span
        ></template>
        <b-list-group>
          <b-list-group-item variant="secondary" class="py-1"
            >Our Benefits</b-list-group-item
          >
          <router-link to="/static/why-whizliz">
            <b-list-group-item>Why Whizliz</b-list-group-item>
          </router-link>
          <router-link to="/static/liz-points">
            <b-list-group-item>Liz-Points</b-list-group-item>
          </router-link>
          <!-- <router-link v-if="!apiUrl.includes('staging')" to="/static/garansi-jual-kembali-100">
            <b-list-group-item>Buyback 100%</b-list-group-item>
          </router-link> -->
          <!-- <router-link v-if="!apiUrl.includes('staging')" to="/static/loyalty-programme">
            <b-list-group-item>Loyalty Programme</b-list-group-item>
          </router-link> -->
          <!-- Liz-Pedia Side Bar -->
          <b-list-group-item variant="secondary" class="py-1"
            >Liz-Pedia</b-list-group-item
          >
          <router-link
            v-for="(lizpedia, i) in lizpedias"
            :key="`lizpedia-${i}`"
            :to="'/lizpedia/' + lizpedia.slug"
          >
            <b-list-group-item>{{ lizpedia.name }}</b-list-group-item>
          </router-link>
          <!-- Liz-Magz Side Bar -->
          <router-link to="/lizmagz">
            <b-list-group-item variant="secondary" class="py-1">
              Liz-Magz
            </b-list-group-item>
          </router-link>
          <router-link
            v-for="(lizmagz, i) in articles.slice(0, 5)"
            :key="`lizmagz-${i}`"
            :to="'/lizmagz/' + lizmagz.slug"
          >
            <b-list-group-item>{{ lizmagz.title }}</b-list-group-item>
          </router-link>

          <div class="px-3 py-2">
            <b-img-lazy :src="banner4.image_url" alt="" fluid />
          </div>
        </b-list-group>
      </b-sidebar>
    </div>
    <header
      class="d-none d-lg-block header"
      :class="[
        headerFixheaderClass,
        isCustomTheme ? 'header-custom-theme' : ''
      ]"
    >
      <div v-if="topmenu.length || ($route.path == '/' && resellerData && !resellerData.is_membership_expired)" class="top-bar" :class="{'bg-gold' : $route.path == '/' && resellerData && !resellerData.is_membership_expired}">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col">
              <div class="row no-gutters text-right">
                <div :class="i == 0 && topmenu.length > 1 ? 'col-7' : 'col'" v-for="(menu, i) in topmenu" :key="i">
                  <marquee v-if="menu.show_type == 'MARQUEE'" onmouseover="this.stop()" onmouseleave="this.start()">
                    <UrlHandler :url="menu.link_url" :class="menuTextColor" >{{menu.text}}</UrlHandler>
                  </marquee>
                  <UrlHandler v-else :url="menu.link_url" :class="menuTextColor">{{menu.text}}</UrlHandler>
                </div>
              </div>
            </div>
            <div v-if="resellerData && !resellerData.is_membership_expired" class="col-auto ml-auto text-right px-2">
              <span class="text-white mr-2 font-12px">Oops! Kamu belum punya membership nih! Segera aktifkan membership kamu yuk</span>
              <router-link tag="button" to="/bus-partner-membership" class="btn btn-wz-outline-white">Buy Membership Now</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-bar pt-3">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col">
              <!-- <a href="#" v-b-modal="'how-to-order'"
                ><i class="icon-shopping-bag-check"></i> How to Order</a
              > -->
            </div>
            <div class="col text-center">
              <router-link to="/" class="bg-transparent">
                <img
                  src="@/assets/img/logo-black.svg"
                  alt=""
                  class="p-3 mw-50"
                  width="2000"
                  :style="{
                    filter: headerFixheaderClass && !isCustomTheme ? 'invert(0)' : 'invert(1)',
                  }"
                />
              </router-link>
            </div>
            <div class="col text-right">
              <ul class="account-menu">
                <li>
                  <router-link
                    v-if="userData"
                    to="/profile"
                    class="bg-transparent"
                  >
                    <img
                      id="my-profile"
                      src="@/assets/img/my-profile.svg"
                      alt=""
                      class="w28h28"
                      :style="{
                        filter: headerFixheaderClass && !isCustomTheme
                          ? 'invert(0)'
                          : 'invert(1)',
                      }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-profile-hover.svg'),
                          'my-profile'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-profile.svg'),
                          'my-profile'
                        )
                      "
                    />
                  </router-link>
                  <a
                    v-else
                    href="#"
                    data-toggle="modal"
                    v-b-modal="'modalLogin'"
                    :class="[menuTextColor]"
                    >Login</a
                  >
                </li>
                <li>
                  <a
                    v-if="userData"
                    v-b-modal.side-wishlist
                    href="#"
                  >
                    <img
                      id="my-whizliz"
                      src="@/assets/img/my-whizliz.svg"
                      alt=""
                      class="w28h28"
                      :style="{
                        filter: headerFixheaderClass && !isCustomTheme
                          ? 'invert(0)'
                          : 'invert(1)',
                      }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-whizliz-hover.svg'),
                          'my-whizliz'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-whizliz.svg'),
                          'my-whizliz'
                        )
                      "
                    />
                  </a>
                  <a
                    v-else
                    href="#"
                    data-toggle="modal"
                    v-b-modal="'modalLogin'"
                  >
                    <img
                      id="my-whizliz"
                      src="@/assets/img/my-whizliz.svg"
                      alt=""
                      class="w28h28"
                      :style="{
                        filter: headerFixheaderClass && !isCustomTheme
                          ? 'invert(0)'
                          : 'invert(1)',
                      }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-whizliz-hover.svg'),
                          'my-whizliz'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-whizliz.svg'),
                          'my-whizliz'
                        )
                      "
                  /></a>
                </li>
                <li>
                  <a
                    href="#"
                    v-b-modal.side-cart
                    class="position-relative"
                    v-if="userData"
                  >
                    <span class="cart-number">{{
                      $store.getters.getShoppingBag.length
                    }}</span>
                    <img
                      id="my-cart"
                      src="@/assets/img/my-cart.svg"
                      alt=""
                      class="w28h28"
                      :style="{
                        filter: headerFixheaderClass && !isCustomTheme
                          ? 'invert(0)'
                          : 'invert(1)',
                      }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-cart-hover.svg'),
                          'my-cart'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-cart.svg'),
                          'my-cart'
                        )
                      "
                    />
                  </a>
                  <a href="#" v-else v-b-modal="'modalLogin'">
                    <img
                      id="my-cart"
                      src="@/assets/img/my-cart.svg"
                      alt=""
                      class="w28h28"
                      :style="{
                        filter: headerFixheaderClass && !isCustomTheme
                          ? 'invert(0)'
                          : 'invert(1)',
                      }"
                      @mouseover="
                        iconImageChange(
                          require('@/assets/img/my-cart-hover.svg'),
                          'my-cart'
                        )
                      "
                      @mouseout="
                        iconImageChange(
                          require('@/assets/img/my-cart.svg'),
                          'my-cart'
                        )
                      "
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="container">
          <div class="row">
            <div class="col-2"></div>
            <div class="col-8 p-0 text-center">
              <ul v-if="loaded">
                <li>
                  <div class="dropdown">
                    <router-link to="/products/new-in" class="dropbtn" :class="[menuTextColor]">
                      WHAT’S NEW
                    </router-link>
                  </div>
                </li>
                <li>
                  <div class="dropdown dropbtn">
                    <a href="#" :class="[menuTextColor]">CATEGORY</a>
                    <div class="dropdown-content dropdown-animation">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-8">
                            <div class="row mega-menu-gap">
                              <div class="col-md-3">
                                <div class="roboto12 bold text-left mega-menu-group-title">
                                  BY PRICE
                                </div>
                                <router-link
                                  to="/products/under5m"
                                  class="roboto12 text-left"
                                >
                                  5.000.000 &amp; Under
                                </router-link>
                                <router-link
                                  to="/products/under15m"
                                  class="roboto12 text-left"
                                >
                                  15.000.000 &amp; Under
                                </router-link>
                                <router-link
                                  to="/products/under25m"
                                  class="roboto12 text-left"
                                >
                                  25.000.000 &amp; Under
                                </router-link>
                              </div>
                              <template v-for="(category, i) in categories">
                                <div
                                  class="col-md-3"
                                  v-for="(cat, j) in category.sub_category"
                                  :key="'categories-' + i + j"
                                >
                                  <div class="roboto12 bold text-left mega-menu-group-title">
                                    {{ cat.name }}
                                  </div>
                                  <router-link
                                    v-for="(subCat, k) in cat.sub_category"
                                    :key="k"
                                    class="roboto12 text-left"
                                    :to="
                                      simpleSlug(
                                        category.slug,
                                        cat.slug,
                                        subCat.slug,
                                        subCat.id
                                      )
                                    "
                                  >
                                    {{ subCat.name }}
                                  </router-link>
                                </div>
                              </template>
                            </div>
                          </div>
                          <div class="col-md-4 p-20 d-flex">
                            <a :href="banner1.url">
                              <b-img-lazy :src="banner1.image_url" alt="" fluid />
                            </a>
                            <a :href="banner2.url">
                              <b-img-lazy :src="banner2.image_url" alt="" fluid />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown dropbtn">
                    <a href="#" :class="[menuTextColor]">SHOP BY BRAND</a>
                    <div class="dropdown-content dropdown-animation pb-0">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-12 text-center mt-4 mb-5" v-if="isGroupedBrandsLoading">
                            <font-awesome icon="spinner" size="8x" spin />
                          </div>
                          <template v-else>
                            <div class="col-md-8">
                              <div class="row mega-menu-gap">
                                  <div
                                    class="col-md-3"
                                    v-for="(group, indexGroup) in groupedBrands.slice(0, 8)"
                                    :key="`brand-category-${group.id}`"
                                  >
                                    <div class="roboto12 bold text-left mega-menu-group-title">
                                      {{ group.name }}
                                    </div>
                                    <router-link
                                      v-for="(brand, indexBrand) in group.brands"
                                      :key="`brand-${brand.id}`"
                                      class="roboto12 text-left"
                                      :to="`/shop-by-brands/${brand.slug}-official-store-${brand.id}`"
                                      @mouseover.native="hoveredBrand = groupedBrands[indexGroup].brands[indexBrand]"
                                    >
                                      {{ brand.name }}
                                    </router-link>
                                  </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                            <BrandShowcaseHeader :brand="hoveredBrand ? hoveredBrand : autoBrand" v-if="hoveredBrand || autoBrand"/>
                            </div>
                            <div class="col-md-12 py-3">
                              <router-link to="/shop-by-brands" class="btn-wz-view-more">
                                VIEW MORE
                              </router-link>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown dropbtn">
                    <a href="#" :class="[menuTextColor]">FIND YOUR STYLE</a>
                    <div v-if="this.$route.path.indexOf('/find-your-style') < 0" class="dropdown-content dropdown-animation pb-0">
                      <div class="container">
                        <div class="row no-gutters row-cols-5 justify-content-center">
                          <div class="col" v-for="(collection, i) in collections.slice(0, 5)" :key="i">
                            <router-link
                              class="find-your-style-container mx-2"
                              :to="`/products/collection/${$helpers.format.slug(`${collection.name} ${collection.id}`)}`"
                              @click.native="firebaseSelectCollection(collection)"
                            >
                              <b-img-lazy :src="collection.image_square" :alt="`${collection.brand_name} ${collection.name}`" fluid-grow />
                              <div class="find-your-style-text-container">
                                <div class="text-boxed">
                                  <b-img-lazy
                                    v-if="collection.brand_name"
                                    height="96"
                                    class="rounded-circle shadow"
                                    :src="brands.find(el => el.name == collection.brand_name).image_banner_square" 
                                    :alt="collection.brand_name"
                                    :title="collection.brand_name"  
                                  />
                                  {{collection.name}}
                                </div>
                              </div>
                            </router-link>
                          </div>
                        </div>
                        <router-link to="/find-your-style" class="my-3 btn-wz-view-more">VIEW MORE</router-link>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown dropbtn">
                    <a href="#" :class="[menuTextColor]">PROMOTION</a>
                    <div :class="{'d-none' : !autoPromo}" class="dropdown-content dropdown-animation pb-0">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-7">
                            <div class="row">
                              <div class="col-md-6" v-for="(promo, i) in promos" :key="i">
                                <router-link 
                                  :to="'/products/' + promo.slug"
                                  class="roboto12 text-left"
                                  @mouseover.native="hoveredPromo = i"
                                  @click.native="firebaseSelectPromotion(promo)"
                                >
                                  {{promo.name}}
                                </router-link>
                              </div>
                            </div>
                          </div>
                          <div v-if="autoPromo" class="col-md-5">
                            <router-link
                              :to="'/products/' + (hoveredPromo ? promos[hoveredPromo].slug : autoPromo.slug)"
                              @click.native="firebaseSelectPromotion(hoveredPromo ? promos[hoveredPromo] : autoPromo)"
                            >
                              <b-img-lazy fluid 
                                :src="hoveredPromo ? promos[hoveredPromo].image_responsive_src : autoPromo.image_responsive_src"
                                :title="hoveredPromo ? promos[hoveredPromo].name : autoPromo.name"
                                :alt="hoveredPromo ? promos[hoveredPromo].name : autoPromo.name"
                              />
                            </router-link>
                          </div>
                          <div class="col-md-12 py-3">
                            <router-link to="/promo" class="btn-wz-view-more">
                              VIEW MORE
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown">
                    <router-link class="dropbtn" to="/our-review" :class="[menuTextColor]">OUR REVIEWS</router-link>
                  </div>
                </li>
                <li>
                  <div class="dropdown dropbtn">
                    <a href="#" :class="[menuTextColor]">WHY WHIZLIZ</a>
                    <div class="dropdown-content dropdown-animation">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-7">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="roboto12 bold text-left mega-menu-group-title">
                                  OUR BENEFITS
                                </div>
                                <router-link
                                  to="/static/why-whizliz"
                                  class="roboto12 text-left"
                                >
                                  Why Whizliz
                                </router-link>
                                <router-link
                                  to="/static/liz-points"
                                  class="roboto12 text-left"
                                >
                                  Liz-Points
                                </router-link>
                                <!-- <router-link
                                  v-if="!apiUrl.includes('staging')"
                                  to="/static/garansi-jual-kembali-100"
                                  class="roboto12 text-left"
                                >
                                  Buyback 100%
                                </router-link> -->
                                <!-- <router-link
                                  v-if="!apiUrl.includes('staging')"
                                  to="/static/loyalty-programme"
                                  class="roboto12 text-left"
                                >
                                  Loyalty Programme
                                </router-link> -->
                              </div>
                              <div class="col-md-4">
                                <div class="roboto12 bold text-left mega-menu-group-title">
                                  LIZ-PEDIA
                                </div>
                                <router-link
                                  v-for="(lizpedia, i) in lizpedias"
                                  :key="i"
                                  :to="'/lizpedia/' + lizpedia.slug"
                                  class="roboto12 text-left"
                                >
                                  {{ lizpedia.name }}
                                </router-link>
                              </div>
                              <div class="col-md-4">
                                <router-link
                                  to="/lizmagz"
                                  class="roboto12 bold text-left"
                                >
                                  LIZ-MAGZ
                                </router-link>
                                <router-link
                                  v-for="(lizmagz, i) in articles.slice(0, 5)"
                                  :key="i"
                                  :to="'/lizmagz/' + lizmagz.slug"
                                  class="roboto12 text-left"
                                >
                                  {{ lizmagz.title }}
                                </router-link>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 p-20 d-flex">
                            <a :href="banner4.url">
                              <b-img-lazy :src="banner4.image_url" alt="" fluid />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li v-for="(adt, i) in adtCategory" :key="i">
                  <div class="dropdown special-offer">
                    <router-link class="dropbtn" :class="[menuTextColor]" :to="'/products/' + adt.slug">
                      {{ adt.name }}
                    </router-link>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-2 column-search">
              <form class="form-search" @submit.prevent="search(searchData)">
                <input
                  id="search-form"
                  v-model="searchData"
                  type="search"
                  placeholder="Search Product..."
                  class="search py-0"
                  :class="{ whiteSearchHeader: headerStyle == 'whiteHeader' }"
                  style="width: calc(100% - 14px)"
                  @keyup="search($event.keyCode === 13 ? searchData : null)"
                  autocomplete="off"
                />
                <img
                  @click="search(searchData)"
                  src="@/assets/img/search.svg"
                  alt=""
                  style="width: 14px; height: 14px; cursor: pointer"
                  :style="{
                    filter: headerFixheaderClass && !isCustomTheme ? 'invert(0)' : 'invert(1)',
                  }"
                />
                <History :data="history" @search="search" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- <b-modal
      id="how-to-order"
      modal-class="p-0"
      centered
      hide-header
      hide-footer
    >
      <div class="modal-dialog" role="document" style="display: contents">
        <div class="modal-content" style="display: contents">
          <div class="modal-header">
            <div class="title-address col-md-6">
              Shopping at Whizliz is Easy!
            </div>
            <button
              type="button"
              class="close m--1510"
              data-dismiss="modal"
              aria-label="Close"
              @click="$bvModal.hide('how-to-order')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="bold">1. Choose Product</p>
            <p>
              Lihat ke produk-produk Whizliz berdasarkan kategori yang muncul di
              bagian atas halaman. Kamu juga bias klik ke New Arrival untuk
              melihat produk-produk terbaru.
            </p>
            <hr />
            <p class="bold">2. Add to Cart</p>
            <p>
              Klik add to cart, isi alamat pengiriman, dan tulis pada Order
              Notes keterangan ukuran (untuk cincin) dan warna emas.
            </p>
            <hr />
            <p class="bold">3. Choose Payment Method</p>
            <p>
              Pilih metode pembayaran yang Anda inginkan. Anda dapat memilih
              melalui metode pembayaran Midtrans atau Kredivo. Pembayaran akang
              langsung terkonfirmasi setelah proses transaksi selesai.
            </p>
            <hr />
            <p class="bold">4. You're Done</p>
            <p>
              Tunggu paket Whizliz sampai di rumah Anda. Jangan lupa untuk
              upload foto Anda beserta perhiasan Whizliz tersebut dan post ke
              Instagram Whizliz.
            </p>
          </div>
        </div>
      </div>
    </b-modal> -->
    <template v-if="isLoggedIn">
      <SideWishlist />
      <SideCart />
    </template>
    <template v-else>
      <ModalLogin @ModalLoginToken="changeToken" />
      <ModalRegister @ModalLoginToken="changeToken" />
      <ModalForgotPassword />
    </template>
    <ModalRegisterReseller />
  </section>
</template>

<script>
import { toaster, cookieMan, userHelper } from "@/_helpers";
import { userService } from "@/_services";
const UrlHandler = () => import('@/components/mains/UrlHandler');

import googleOneTap from 'google-one-tap';

const History = () => import("@/components/mains/History");
const SideCart = () => import("@/components/modals/SideChart.vue");
const SideWishlist = () => import("@/components/modals/SideWishlist.vue");
const ProfileNavbar = () => import("@/components/mains/ProfileNavbar");
const BrandShowcaseHeader = () => import("@/components/mains/BrandShowcaseHeader");
import ModalLogin from "@/components/modals/ModalLogin.vue";
import ModalRegister from "@/components/modals/ModalRegister.vue";
import ModalRegisterReseller from "@/components/modals/ModalRegisterReseller";
import ModalForgotPassword from "@/components/modals/ModalForgotPassword.vue";

export default {
  name: "Header",
  components: {
    UrlHandler,
    History,
    SideCart,
    SideWishlist,
    ProfileNavbar,
    BrandShowcaseHeader,
    ModalLogin,
    ModalRegister,
    ModalRegisterReseller,
    ModalForgotPassword,
  },
  props: ["parentData", "fixed"],
  data() {
    return {
      headerStyle: this.parentData,
      localFixed: this.fixed,
      headerFixheaderClass: "",
      openCart: false,
      openWishlist: false,
      // Start menu
      topmenu: [],
      categories: [],
      brands: [],
      groupedBrands: [],
      collections: [],
      promos: [],
      articles: [],
      lizpedias: [],
      banner1: null,
      banner2: null,
      banner3: null,
      banner4: null,
      adtCategory: [],
      loaded: false,
      hoveredCollection: null,
      autoCollection: null,
      hoveredPromo: null,
      autoPromo: null,
      hoveredBrand: null,
      autoBrand: null,
      // End menu
      searchData: this.$route.params.keyWord ?? null,
      searchUrl: null,
      isGroupedBrandsLoading: true,
      // Side cart adjust quantity
      insufficientProducts: [],
    };
  },
  computed: {
    menuTextColor: function() {
      if (this.isCustomTheme) return 'text-white';
      else return ''; //DEFAULT Theme
    },
    isCustomTheme() {
      return this.$store.getters.getWhizlizTheme == 'CHRISTMAS';
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
    history() {
      return {
        product: JSON.parse(cookieMan.get("fe_history_product") || '[]'),
        search: JSON.parse(cookieMan.get("fe_history_search") || '[]'),
        brand: this.$store.getters.getBrandHistory,
      }
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    }
  },
  mounted() {
    if (this.$route.path.indexOf('/account-delete') >= 0) {
      this.deactivateAccount(this.$route.params.code)
      this.$router.replace('/')
    }
    this.initGoogleOneTap()
    this.getHeaderMenu();
    this.getGroupedBrand();
    this.getTopMenu();
    this.initHeaderStyle()

    window.addEventListener("scroll", this.handleScroll);
    if (this.isLoggedIn) {
      this.getCart()
      this.getWishList()
    }
  },
  methods: {
    initHeaderStyle() {
      if (this.headerStyle == "mainHeader")
        this.headerFixheaderClass = ""
      else if (this.headerStyle == "whiteHeader")
        this.headerFixheaderClass = "head-white"
    },
    handleScroll() {
      //  console.log(this.token);
      if (window.scrollY >= 80) {
        if (this.headerStyle == "mainHeader") {
          this.headerFixheaderClass = "fixHeader";
        } else if (this.headerStyle == "whiteHeader") {
          this.headerFixheaderClass = "head-white fixHeader";
        }
      } else {
        if (this.headerStyle == "mainHeader") {
          this.headerFixheaderClass = "";
        } else if (this.headerStyle == "whiteHeader") {
          this.headerFixheaderClass = "head-white";
        }
      }
      //   head-white d-none d-lg-block
      // Any code to be executed when the window is scrolled
    },
    closeSidebars() {
      const sidebars = document.getElementsByClassName('b-sidebar')
      for (let i = 0; i < sidebars.length; i++) 
        sidebars[i].style.display = "none"
    },
    simpleSlug(slug1, slug2, slug3, id) {
      const slug_3 = slug3.replace(`${slug2}-`, "");
      const slug_2 = slug2.replace(`${slug1}-`, "");
      return `/products/${slug1}/${slug_2}/${slug_3}-${id}`;
    },
    search(query) {
      if (query) {
        this.searchData = query
        const index = this.history.search.findIndex(el => el == this.searchData)
        if (index >= 0) {
          this.history.search.splice(index, 1)
          this.history.search.unshift(this.searchData)
        } else {
          this.history.search.unshift(this.searchData)
          if (this.history.search.length > 3) this.history.search.pop()
        }
        cookieMan.put("fe_history_search", JSON.stringify(this.history.search))
        this.searchUrl = "/products/search/" + this.searchData;
        this.$router.push(this.searchUrl);
      }
    },
    // Test Google One Tap (temporary)
    initGoogleOneTap() {
      if (this.isLoggedIn) return
      const options = {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID, // required
        auto_select: false, // optional
        cancel_on_tap_outside: false, // optional
        context: 'signin', // optional
      };
      googleOneTap(options, async (response) => {
        // TODO hit API for one tap google
        const res = await this.$api.user.signinGoogleOneTap(response.credential)
        if (res.status === 200) {
          toaster.make(`Welcome, ${res.data.data.profile_data.full_name}`, 'success')
          this.changeToken(true)
        }
      })
    },
    changeToken(token) {
      if (token) {
        this.$bvModal.hide("modalLogin");
        this.$bvModal.hide("modalRegister");
      }
    },
    async getHeaderMenu() {
      // https://stackoverflow.com/a/44185591
      const [categories, brands, collections, promos, articles, lizpedias, banner1, banner2, banner3, banner4, adtCategory] = await Promise.all([
        this.$store.getters.getCategoriesList.length > 0
        ? {data: {data: this.$store.getters.getCategoriesList}}
        : this.$api.cms.getCategories(),

        this.$store.getters.getBrandsList.length > 0
        ? {data: {data: this.$store.getters.getBrandsList}}
        : this.$api.cms.getBrands(),

        this.$store.getters.getCollectionsList.length > 0
        ? {data: {data: this.$store.getters.getCollectionsList}}
        : this.$api.cms.getCollections(),

        this.$store.getters.getPromoList.length > 0
        ? {data: {data: this.$store.getters.getPromoList}}
        : this.$api.cms.getPromotions(),
        this.$api.cms.getArticles(),
        this.$api.cms.getLizpedia(),
        this.$api.cms.getMegaMenuBanner(1),
        this.$api.cms.getMegaMenuBanner(2),
        this.$api.cms.getMegaMenuBanner(3),
        this.$api.cms.getMegaMenuBanner(4),
        this.$api.cms.getAdditionalCategory()
      ])
      this.categories = categories.data.data;
      this.brands = brands.data.data;
      this.collections = collections.data.data;
      this.promos = promos.data.data
      this.articles = articles.data.data;
      this.lizpedias = lizpedias.data.data;
      this.banner1 = banner1.data.data;
      this.banner2 = banner2.data.data;
      this.banner3 = banner3.data.data;
      this.banner4 = banner4.data.data;
      this.adtCategory = adtCategory.data.data
      this.loaded = true;
      this.$store.dispatch("setBrands", this.brands);
      this.$store.dispatch("setCollections", this.collections);
      this.$store.dispatch("setPromos", this.promos);
      this.$store.dispatch("setCategories", this.categories);
      this.collectionsAutoplay();
      this.promotionsAutoplay();
      if (this.$route.path == "/") {
        setTimeout(() => {
          window.prerenderReady = true
        }, 3000)
      }
    },
    async getGroupedBrand(){
      try {
        const res = await this.$api.cms.getGroupedBrands(10)
        if (res.status === 200) {
          this.groupedBrands = res.data.data;
          this.$store.dispatch("setGroupedBrands", this.groupedBrands);
          this.brandsAutoplay();
          this.isGroupedBrandsLoading = false;
        }
      } catch (e) {
        this.isGroupedBrandsLoading = false;
        console.error(e)
      }
    },
    async getTopMenu() {
      try {
        const res = await this.$api.cms.getTopMenu()
        if (res.status === 200) {
          this.topmenu = res.data.data
          this.$store.dispatch('setTopmenu', this.topmenu)
        } else toaster.make(res.data.message, 'danger');
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger');
        console.error(e);
      }
    },
    setCollectionSlug() {
      for (let i = 0; i < this.collections.length; i++) {
        this.collections[i].slug = `collection/${this.$helpers.format.slug(`${this.collections[i].name} ${this.collections[i].id}`)}`
      }
    },
    collectionsAutoplay(index = 0) {
      if (index == this.collections.length) index = 0;
      this.autoCollection = this.collections[index];
      setTimeout(() => {
        this.collectionsAutoplay(index + 1);
      }, 3000);
    },
    promotionsAutoplay(index = 0) {
      if (index == this.promos.length) index = 0;
      this.autoPromo = this.promos[index];
      setTimeout(() => {
        this.promotionsAutoplay(index + 1);
      }, 3000);
    },
    brandsAutoplay(indexGroup = 0, indexBrand = 0) {
      if (indexGroup == this.groupedBrands.length) indexGroup = 0
      this.autoBrand = this.groupedBrands[indexGroup].brands[indexBrand];
      // condition for last index brand in group category
      if (indexBrand == this.groupedBrands[indexGroup].brands.length - 1) {
        indexGroup = indexGroup + 1 // next group category index
        indexBrand = 0 // reset brand index
      } else indexBrand = indexBrand + 1 // next brand index
      setTimeout(() => {
        this.brandsAutoplay(indexGroup, indexBrand);
      }, 3000);
    },
    firebaseSelectCollection(data) {
      this.$firebaseLogEvent("select_collection", {
        collection_id: data.id.toString(),
        collection_name: data.name,
      });
    },
    firebaseSelectPromotion(data) {
      this.$firebaseLogEvent("select_promotion", {
        promotion_name: data.name,
      });
    },
    async getWishList() {
      try {
        const res = await this.$api.user.getWishlist()
        if (res.status === 200) this.$store.dispatch("setWishlist", res.data.data)
      } catch (e) {
        console.error(e);
      }
    },
    async getCart() {
      try {
        userHelper.setCartList()
      } catch (e) {
        console.error(e);
      }
    },
    async logout(deactivate) {
      try {
        const res = await userService.logout()
        if (deactivate) return true
        else if(res.status === 200) {
          toaster.make("You will be redirected to homepage in 3 seconds", "success")
          setTimeout(() => {
            this.$router.push("/");
            this.$store.dispatch('resetUserState')
          }, 2000);
        }
      } catch (e) {
        console.error(e)
      }
    },
    async deactivateAccount(code) {
      try {
        await this.logout(true)
        const res = await this.$api.user.deactivateAccount(code)
        toaster.make(res.data.message, res.status === 200 ? 'success' : 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    iconImageChange(image, id) {
      const element = document.getElementById(id);
      element.src = image;
    },
  },
  watch: {
    isLoggedIn(newVal) {
      if (newVal) {
        this.getCart();
        this.getWishList();
      }
    },
    fixed(newVal) {
      this.localFixed = newVal
    },
    parentData(newVal) {
      this.headerStyle = newVal
      this.initHeaderStyle()
    }
  }
};
</script>

<style scoped>
.header {
  position: sticky;
}
.w28h28 {
  width: 28px;
  height: 28px;
}
.topmenu-marquee {
  margin-bottom: -4px;
  background: #101820;
}
.topmenu-marquee * {
  color: #fff;
}
.list-group-item-topmenu * {
  color: #d89700 !important;
}
/* Add custom gap for each row in mega menu (except first four row > child)*/
.mega-menu-gap > .col-md-3:not(:nth-child(-n + 4)) {
  margin-top: .5rem;
}
.pb-dot6rem {
  padding-bottom: .6rem;
}
.btn-wz-view-more:hover {
  border: 1px solid #d89700 !important;
}
.btn-wz-view-more {
  display: inline-block !important;
  font-size: 0.7rem;
  border: 1px solid black !important;
  border-radius: .5rem;
  padding: 4px 12px !important;
}

/* For Whizliz Custom Theme */
.header-custom-theme:is(.fixHeader, .head-white) {
  background-image: url('../../assets/img/header-whizliz-christmas.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 992px) {
  .header.header-fixed {
    position: fixed !important;
  }
}
</style>
